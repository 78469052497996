exports.components = {
  "component---src-pages-2025-leavers-hoodies-js": () => import("./../../../src/pages/2025-leavers-hoodies.js" /* webpackChunkName: "component---src-pages-2025-leavers-hoodies-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-meta-js": () => import("./../../../src/pages/meta.js" /* webpackChunkName: "component---src-pages-meta-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-year-11-js": () => import("./../../../src/pages/year-11.js" /* webpackChunkName: "component---src-pages-year-11-js" */),
  "component---src-pages-year-6-js": () => import("./../../../src/pages/year-6.js" /* webpackChunkName: "component---src-pages-year-6-js" */)
}

